<template>
  <v-dialog
    v-model="value"
    hide-overlay
    transition="dialog-bottom-transition"
    max-width="800px"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{ item.id ? "Editar" : "Cadastrar" }} Proxy
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-card outlined>
          <v-form ref="formMonitoramento">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome Proxy"
                    v-model="item.name"
                    :counter="255"
                    :rules="requiredField"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Url do Proxy"
                    v-model="item.url"
                    :counter="255"
                    :rules="urlField"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete
                    :items="this.$store.getters.getGroupSelect"
                    label="Grupo"
                    v-model="item.groupId"
                    :rules="requiredField"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Secret"
                    v-model="item.secret"
                    :counter="255"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="item.description"
                    rows="3"
                    label="Descrição"
                    hint="Digite uma descrição se necessario"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                  <v-col cols="auto">
                <v-switch v-model="item.enabled" label="Ativo"></v-switch>
              </v-col>
                
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="$emit('closeDialoag')"
                >
                  Cancelar
                </v-btn>
                <v-btn color="success" text @click="save"> Salvar </v-btn>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { requiredField, urlField } from "@/Utils/InputValidation";
export default {
  name: "FormProxy",
  components: {},
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      requiredField,
      urlField,
    };
  },
  methods: {
    save() {
      if (!this.$refs.formMonitoramento.validate()) {
        return;
      }
      this.$emit("save", this.item);
    },
    close() {
      this.$refs.formMonitoramento.resetValidation();
      this.$emit("closeDialoag");
    },
  },
  mounted() {
    if (this.$store.getters.getGroups.length == 1) {
      this.item.groupId = this.$store.getters.getGroups[0].id;
    }
  },
};
</script>

<style>
</style>