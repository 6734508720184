<template>
  <v-dialog
    v-model="value"
    fullscreen
    transition="dialog-bottom-transition"
    hide-overlay
  >
    <!-- scrollable -->
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Ver Proxy</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('input', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-black">Indice:</td>
                  <td>{{ item.id }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Nome:</td>
                  <td>{{ item.name }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Url:</td>
                  <td>{{ item.url }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Grupo:</td>
                  <td>{{ $store.getters.getGroupNameById(item.groupId) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Secret:</td>
                  <td>{{ item.secret }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Descrição:</td>
                  <td>{{ item.description }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Ativo:</td>
                  <td>{{ item.enabled ? "Sim" : "Não" }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Cadastrado em:</td>
                  <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                </tr>
                <tr>
                  <td class="font-weight-black">Editado em:</td>
                  <td>{{ new Date(item.updatedAt).toLocaleString() }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-row class="mt-2">
          <v-col>
            <v-card outlined>
              <v-card-title>Cameras do proxy</v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">ID</th>
                        <th class="text-left">Nome</th>
                        <th class="text-left">Último evento</th>
                        <th class="text-left">Conectada</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="c in status" :key="c.id" :class="getLineColor(c)">
                        <td class="font-weight-black">{{ c.id }}</td>
                        <td class="font-weight-black">{{ c.name }}</td>
                        <td class="font-weight-black">
                          {{
                            c.status
                              ? new Date(c.status.lastEvent).toLocaleString()
                              : ""
                          }}
                        </td>
                        <td class="font-weight-black">
                          {{
                            c.status
                              ? c.status.conected
                                ? "SIM"
                                : "NÃO"
                              : "Não"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VerMonitoramento",
  components: {},
  methods: {
    getLineColor(item) {
      
      if (item.status && typeof item.status.conected == "boolean") {
        return item.status.conected ? "green darken-4" : "red darken-4";
      }
    },
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    value: {
      type: Boolean,
      required: true,
    },
    status: {
      type: Array,
    },
  },
};
</script>

<style>
</style>